/*
 Knight Bootstrap theme by BootstrapTaste.com
 Theme URI: http://bootstraptaste.com/knight-free-bootstrap-theme/
 Author: BootstrapTaste
 Author URI: http://bootstraptaste.com
 */

/* CSS Document */

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Float Elements 
--------------------------------*/

.fl-lt {
    float: left;
}

.fl-rt {
    float: right;
}

/* Clear Floated Elements
---------------------------------*/

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clearfix:before,
.clearfix:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clearfix:after {
    clear: both;
}

.figure {
    margin: 0px;
}

img {
    max-width: 100%;
}

a,
a:hover,
a:active {
    outline: 0px !important
}

@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot?v=4.1.0');
    src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff?v=4.1.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.1.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Manipulate
--------------------------------*/

.center {
    text-align: center;
}

.center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.center-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.midcontainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}


/* Primary Styles
---------------------------------*/

* {
    cursor: url("cursor.png"), url(cursor.cur), auto;
}

body {
    background: url('../img/subtle_dots_2x.png') !important;
    background-repeat: repeat;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #888888;
    margin: 0;


}

h2 {
    font-size: 34px;
    color: #333;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
    margin: 0 0 15px 0;
    text-align: center;
    text-transform: uppercase;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-size: 16px;
    margin: 0 0 5px 0;
    text-transform: uppercase;
    font-weight: 400;
}

h6 {
    font-size: 16px;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 0 0 60px 0;
}

p {
    line-height: 24px;
    margin: 0;
}

/* Header Styles
---------------------------------*/

.myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -777;
    background-size: cover;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    
}

.header {
    min-height: 30rem;
    text-align: center;
    position: relative;
    display: table;
    width: 100vw;
    height: 100vh;
    padding-top: 8rem;
    padding-bottom: 8rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 10px 100px -5px black;
}

.logo {
    width: 300px;
    margin: 0 auto 50px;
    border-radius: 200px;
    cursor: url("cursor.png"), url(cursor.cur), auto;
}

.logo-smaller {
    width: 100px;
    margin: 0px auto 9px;
    border-radius: 100px;
}

.header h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 70px;
    font-weight: 400;
    letter-spacing: -1px;
    margin: 0 0 30px 0;
    padding: 15px 0px 15px 0px;
    background: #fff;
    color: #333;
    border-radius: 15px;
}

.we-create {
    padding: 15px 35px;
    margin: 0 0 55px;

}

.wp-pic {
    margin-bottom: 20px;
}

.we-create li {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 5px 0 0;
    padding: 15px 15px;
    border-radius: 10px;
}

.we-create li:first-child {
    background: #333;
}

.start-button {
    padding-left: 0px;
}

.start-button li a {
    color: #fff;
}


.link {
    padding: 15px 35px;
    background: #7accff;
    color: #fff !important;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    border-radius: 3px;
    text-transform: uppercase;
    line-height: 25px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.link:hover {
    text-decoration: none;
    color: #333 !important;
    background: #e0e0e0;
}

.link:active,
.link:focus {
    /*background: #35a835;*/
    text-decoration: none;
    /*color:#222222 !important;*/
}

/* Navigation
---------------------------------*/

.main-nav-outer {
    padding: 0px;
    border-bottom: 1px solid lightgray;
    box-shadow: 0px 10px 100px -5px black;
    position: relative;
    background: #fff;
}

.main-nav {
    text-align: center;
    margin: 10px 0 0px;
    padding: 0px;
    list-style: none;
    background: white;
    color: #333;
}

.main-nav li {
    display: inline;
    margin: 0 1px;
}

.main-nav li a {
    display: inline-block;
    color: #333;
    background: #fff;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    text-decoration: #000000;
    text-decoration-color: #000000;
    border-radius: 15px;
    line-height: 20px;
    margin: 17px 15px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.main-nav li a:hover {
    text-decoration: none;
    color: #000000;
}

.small-logo {
    padding: 0 32px;
}

.main-section {
    padding: 90px 0 110px;
    z-index: 30;
    background-color: #fff;
}

.background {
    background: #222222;
    background-color: #222222;
    z-index: 0;
}


/* Services
---------------------------------*/

.service-list {
    padding: 0 0 0 0;
    font-size: 14px;
    margin-bottom: 40px;
}

.service-list-col1 {
    float: left;
    width: 60px;
}

.service-list-col1 i {
    font-style: normal;
    font-size: 38px;
    display: block;
    color: #222;
    font-family: 'FontAwesome';
    line-height: 38px;
}

.service-list-col2 {
    overflow: hidden;
}

.main-section.alabaster {
    background: #fafafa;
}


/* About Me
---------------------------------*/

/* Portfolio
---------------------------------*/

.Portfolio-nav {
    padding: 0;
    margin: 0 0 45px 0;
    list-style: none;
    text-align: center;
}

.Portfolio-nav li {
    margin: 0 10px;
    display: inline;
}

.Portfolio-nav li a {
    display: inline-block;
    padding: 10px 22px;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    background: #f7f7f7;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.Portfolio-nav li a:hover {
    background: #7cc576;
    color: #fff;
    text-decoration: none;
}

.portfolioContainer {
    margin: 0 auto;
    padding-left: 15px;
}

.Portfolio-box {
    text-align: center;
    margin-bottom: 30px;
    height: 350px;
    width: 350px;
    overflow: hidden;
    float: left;
    padding: 0;
}

.Portfolio-box img {
    margin-bottom: 25px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.Portfolio-box img:hover {
    opacity: 0.6;
}

.Portfolio-nav li a.current {
    background: #7cc576;
    color: #fff;
    text-decoration: none;
}

img {
    max-width: 100%;
}

/* no transition on .isotope container */

.isotope .isotope-item {
    /* change duration value to whatever you like */
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    transition-duration: 0.6s;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}

.main-section.paddind {
    padding-bottom: 60px;
}

/* skillz
---------------------------------*/

.client-part {
    background: url(../img/skillz-bg.jpg) center center no-repeat;
    background-size: cover;
    padding: 55px 0;
    text-align: center;
}

.client-part:before {
    background: rgba(255,
        102,
        0,
        0.6);
}

.client-part-haead {
    color: #333;
    font-size: 28px;
    line-height: 41px;
    margin: 30px 0 10px;
    font-family: ''Open Sans',sans-serif';
    font-style: italic;
}

.client {
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
    text-align: center;
}

.client li {
    display: inline;
    margin: 0 15px;
}

.client li a {
    display: inline-block;
    text-decoration: none;
}

.client li a img {
    margin-bottom: 15px;
    border-radius: 50%;
}

.client li a:hover {}

.client li a h3 {
    color: #333;
}

.client li a span {
    color: #333;
}

.quote-right {
    font-style: normal;
    width: 90px;
    height: 68px;
    margin: 0 auto;
    border: 2px solid #333;
    border-radius: 15%;
    display: block;
    line-height: 62px;
    text-align: center;
    font-size: 27px;
    color: #333;
    cursor: url("cursor.png"), url(cursor.cur), auto;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

/*
.quote-right:hover {
    color: #333;
    border: 2px solid #fff;
}
*/

.c-logo-part {
    background: #222222;
    padding: 0px 0;
    filter: alpha(opacity=99);
}

.c-logo-part ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.c-logo-part ul li {
    display: inline;
    margin: 0 25px;
}

.c-logo-part ul a {
    display: inline-block;
    margin: 0 20px;
}

.main-section.team {
    padding: 85px 0;
}

.main-section.team h6 {
    margin-bottom: 40px;
}


/* Talk Business
---------------------------------*/

.business-talking {
    background: url(../img/talk-biz.jpg) top center no-repeat;
    background-size: cover;
    padding: 50px 0 10px;
    text-align: center;
    box-shadow: 0px 2px 20px -5px black;
}

.business-talking h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    padding: 0;
    margin: 20px 0 70px;
    text-transform: uppercase;
    font-size: 42px;
    color: #333;
}


/* Contact
---------------------------------*/

.main-section.contact {
    padding: 9px 0 100px;
}

.main-section.contact {
    background: url(../img/bg-map.png) left 190px no-repeat;
}

.contact-info-box {
    font-size: 15px;
    margin: 0 0 14px 68px;
    padding-left: 0;
}

.contact-info-box h3 {
    font-size: 15px;
    font-weight: 400;
    float: left;
    width: 102px;
    margin-right: 12px;
    line-height: 28px;
}

.contact-info-box h3 i {
    font-style: normal;
    font-size: 18px;
    color: #222222;
    font-family: 'FontAwesome';
    font-weight: normal;
    margin-right: 7px;
}

.contact-info-box span {
    line-height: 28px;
    display: block;
    overflow: hidden;
}

.social-link {
    padding: 35px;

    display: block;
    overflow: hidden;
    list-style: none;
    text-align: center;
}

.social-link li {
    text-align: center;

}

.social-link li a {
    margin: 10px;
    padding: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    color: #333;
    background: #fff;
    transition: all 0.3s ease-in-out;
}

.social-link li a:hover,
.social-link li a:focus {
    text-decoration: none;
}

.twitter a:hover {
    background: #55acee;
}

.facebook a:hover {
    background: #7fff00;
}

.pinterest a:hover {
    background: #cb2026;
}

.gplus a:hover {
    background: #dd4b39;
}

.dribbble a:hover {
    background: #008B8B;
}

.form {
    margin: 0 66px 0 3px;
}

.input-text {
    padding: 15px 16px;
    border: 1px solid #e9e9e9;
    width: 100%;
    height: 50px;
    display: block;
    border-radius: 6px;
    font-size: 15px;
    color: #aaa;
    font-family: 'Open Sans', sans-serif;
    margin: 0 0 10px 0;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    background: #fefefe;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-text:focus {
    border: 1px solid #7accff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
}

.input-text.text-area {
    height: 165px;
    resize: none;
    overflow: auto;

}

.input-btn {
    width: 175px;
    height: 50px;
    background: #333;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border: 0px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.input-btn:hover {
    background: #111;
    color: #fff;
}

/* Footer
------------------------------*/

.footer {
    /*background: url(../img/footer-bg.jpg) center center no-repeat;*/
    background-image: linear-gradient(#4e9ee8, #8ec2f2);
    background-size: cover;
    padding: 35px 0px 35px;
}

.footer-logo { 
    margin: auto;
    width: 76px;
    align-content: center;
}

.copyright {
    color: #cccccc;
    font-size: 14px;
    display: block;
    text-align: center;
}

.copyright a {
    color: #7cc576;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.copyright a:hover {
    color: #fff;
}

.res-nav_click {
    line-height: 38px;
    font-family: 'FontAwesome';
    font-size: 38px;
    text-decoration: none !important;
    color: #777777;
    font-style: normal;
    display: none;
    width: 42px;
    height: 27px;
    margin: 20px auto;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.res-nav_click:hover,
.res-nav_click:active,
.res-nav_click:focus {
    color: #7cc576 !important;
}

.portfolioContainer {
    max-width: 1140px;
}
